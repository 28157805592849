<script setup>
import {
    ref,
    computed,
    defineAsyncComponent,
    useSlots,
    defineProps // eslint-disable-line
} from 'vue';

import { kebabCase } from 'lodash-es';

const props = defineProps({
    icon: {
        type: String,
        default: null
    },

    autoColor: {
        type: Boolean,
        default: true
    },

    hasStroke: {
        type: Boolean,
        default: false
    },
    hasFill: {
        type: Boolean,
        default: true
    },

    viewbox: {
        type: String,
        default: null
    },

    svgAttrs: {
        type: Object,
        default() {
            return {};
        }
    },

    prefix: {
        type: String,
        default: 'icon'
    }
});

const slots = useSlots();

const id = ref(Math.floor(Math.random() * 10000000));

const attrs = computed(() => {
    const attributes = {
        role: 'presentation'
    };

    if (props.viewbox) {
        attributes.viewBox = props.viewbox;
    }

    return {
        ...attributes,
        ...props.svgAttrs
    };
});

const isInline = computed(() => {
    // We can access the default slot property, which always is a function.
    // When any html was entered in the default slot, it should be rendered
    // within the icon component as 'inline' html.
    //
    // Note: a slot function always returns an array of vNodes
    if (!slots || typeof slots.default !== 'function' || !slots.default().length) {
        return false;
    }

    return true;
});

/**
 * Glob-based icon loading
 */
function importIcons() {
    function getFileNameFromPath(path) {
        return path
            .replace(/^(?:.*)(?:\/assets\/img\/icons\/)(.+)\.svg$/i, '$1')
            .replace('/', '-');
    }

    // Redefine bash path, can only use literals
    const iconImports = import.meta.glob(
        '@/assets/img/icons/**/*.svg',
        {
            query: 'component=true&lang.svg',
            import: 'default',
        }
    );

    const imported = {};
    Object.keys(iconImports).forEach((path) => {
        const iconName = kebabCase(getFileNameFromPath(path));
        imported[iconName] = iconImports[path];
    });
    return imported;
}

let icons = [];
if (!isInline.value) {
    icons = importIcons();
}

const iconComponent = computed(() =>
    icons[props.icon] ? defineAsyncComponent(icons[props.icon]) : null
);

</script>

<script>
export default {
    name: 'DnIcon'
};
</script>

<template>
    <span
        v-if="iconComponent || isInline"
        class="dn-icon"
        :data-icon="icon"
        :class="{
            'dn-icon--autocolor': autoColor,
            'dn-icon--has-stroke': autoColor && hasStroke,
            'dn-icon--has-fill': autoColor && hasFill,
        }"
    >
        <svg
            v-if="isInline"
            xmlns="http://www.w3.org/2000/svg"
            :aria-labelledby="`${id}-title`"
            v-bind="attrs"
        >
            <title :id="`${id}-title`"><slot name="title">Untitled</slot></title>

            <g>
                <slot />
            </g>
        </svg>

        <component
            :is="iconComponent"
            v-bind="attrs"
        />
    </span>
</template>

<style lang="less" src="./icon.less"></style>
