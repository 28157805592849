<template>
    <div
        v-if="items && items.length"
        class="slider"
    >
        <DragToScroll
            ref="scrollContainer"
            class="slider__track"
        >
            <slot name="default">
                <div
                    v-if="hasPadding"
                    class="slider__padding"
                />

                <div
                    v-for="(item, index) in items"
                    ref="items"
                    :key="`slide_${index}`"
                    class="slider__item"
                    @enter="itemIsVisible[index] = true"
                    @leave="itemIsVisible[index] = false"
                >
                    <slot
                        name="slide"
                        :item="item"
                        :index="index"
                        :is-visible="itemIsVisible[index]"
                    />
                </div>

                <div
                    v-if="hasPadding"
                    class="slider__padding"
                />
            </slot>
        </DragToScroll>

        <slot
            v-if="controlsEnabled"
            name="controlLeft"
            :scroll-left="scrollLeft"
            :is-visible="overflowLeft"
        >
            <transition :name="controlTransitionName">
                <button
                    v-if="overflowLeft"
                    data-left
                    class="slider__control"
                    type="button"
                    @click.prevent="scrollLeft"
                >
                    <slot name="controlLeftLabel">Left</slot>
                </button>
            </transition>
        </slot>

        <slot
            v-if="controlsEnabled"
            name="controlRight"
            :scroll-right="scrollRight"
            :is-visible="overflowRight"
        >
            <transition :name="controlTransitionName">
                <button
                    v-if="overflowRight"
                    data-right
                    class="slider__control"
                    type="button"
                    @click.prevent="scrollRight"
                >
                    <slot name="controlRightLabel">Right</slot>
                </button>
            </transition>
        </slot>
    </div>
</template>

<script>
import { nextTick } from 'vue';
import DragToScroll from './DragToScroll.vue';

export default {
    name: 'DnSlider',

    components: {
        DragToScroll
    },

    props: {
        items: {
            type: Array,
            default: null
        },

        controlsEnabled: {
            type: Boolean,
            default: false
        },

        hasPadding: {
            type: Boolean,
            default: true
        },

        controlTransitionName: {
            type: String,
            default: 'fade'
        },

        trackIntersection: {
            type: Boolean,
            default: false
        },

        intersectionThreshold: {
            type: Number,
            default: 0.75
        }
    },

    data() {
        return {
            overflowRight: false,
            overflowLeft: false,
            intersectionObserver: null,
            itemIsVisible: []
        };
    },

    watch: {
        items(value) {
            // Populate internalItemTracker with the indexes from the items prop and a nul lvalue for each
            this.itemIsVisible = value.map(() => null);
        },
        controlsEnabled(controlsEnabled) {
            if (controlsEnabled) {
                return this.enableControls();
            }

            return this.disableControls();
        },
        trackIntersection(trackingIntersection) {
            if (trackingIntersection) {
                return this.addIntersectionObservers();
            }

            return this.removeIntersectionObservers();
        }
    },

    async mounted() {
        if (this.controlsEnabled) {
            this.enableControls();
        }

        if (this.trackIntersection) {
            await nextTick();
            this.addIntersectionObservers();
        }
    },

    unmounted() {
        if (this.controlsEnabled) {
            this.disableControls();
        }

        if (this.trackIntersection) {
            this.removeIntersectionObservers();
        }
    },

    methods: {
        enableControls() {
            this.calculatePositions();
            window.addEventListener('resize', this.calculatePositions);
            this.$refs.scrollContainer.$el.addEventListener('scroll', this.calculatePositions);
        },

        disableControls() {
            window.removeEventListener('resize', this.calculatePositions);

            if (this.$refs.scrollContainer && this.$refs.scrollContainer.$el) {
                this.$refs.scrollContainer.$el.removeEventListener('scroll', this.calculatePositions);
            }
        },

        calculatePositions() {
            if (!this.$refs.items || !this.$refs.items.length) {
                return;
            }

            const firstCardOffset = this.$refs.items[0].getBoundingClientRect(),
                lastCardOffset = this.$refs.items[this.$refs.items.length - 1].getBoundingClientRect();

            // these are relative to the viewport, i.e. the window
            this.overflowLeft = firstCardOffset.left < 0;
            this.overflowRight = lastCardOffset.right > window.innerWidth;
        },

        scroll(direction) {
            let cardWidth = 0;

            if (this.$refs.items && this.$refs.items.length) {
                cardWidth = this.$refs.items[0].offsetWidth;
            }

            const element = this.$refs.scrollContainer.$el;
            let scrollX = 0;

            if (direction === 'left') {
                scrollX = element.scrollLeft - cardWidth;
            } else if (direction === 'right') {
                scrollX = element.scrollLeft + cardWidth;
            } else {
                return;
            }

            element.scrollTo({
                left: scrollX,
                behavior: 'smooth'
            });

            this.calculatePositions(scrollX);
        },

        scrollLeft() {
            this.scroll('left');
        },

        scrollRight() {
            this.scroll('right');
        },

        addIntersectionObservers() {
            if (!this.intersectionObserver) {
                const observerOptions = {
                    threshold: this.intersectionThreshold
                };

                this.intersectionObserver = new IntersectionObserver(this.onIntersect, observerOptions);
            }

            this.$refs.items.forEach((el) => {
                this.intersectionObserver.observe(el);
            });
        },

        onIntersect(entries) {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    entry.target.dispatchEvent(new Event('enter'));
                } else {
                    entry.target.dispatchEvent(new Event('leave'));
                }
            }
        },

        removeIntersectionObservers() {
            this.intersectionObserver?.disconnect();
        }
    }
};
</script>

<style lang="less" src="./Slider.less"></style>
