<template>
    <div
        v-if="!supportsTouch && mounted"
        v-dragscroll
        class="drag-to-scroll"
        :class="{
            'is-dragging' : isDragging
        }"
        @dragscrollstart="handleDragStart"
        @dragscrollend="handleDragEnd"
    >
        <slot />
    </div>
    <div
        v-else
        :data-touch-support="supportsTouch ? 'true' : 'false'"
    >
        <slot />
    </div>
</template>

<script>
import { dragscroll } from 'vue-dragscroll';

export default {
    directives: {
        dragscroll
    },

    data() {
        return {
            isDragging: false,
            supportsTouch: false,
            mounted: false
        };
    },

    mounted() {
        if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
            this.supportsTouch = true;
        }

        // Mounted check is needed for the conditional rendering
        // of the dragToScroll component.
        this.mounted = true;
    },

    methods: {
        handleDragStart() {
            this.isDragging = true;
        },
        handleDragEnd() {
            this.isDragging = false;
        }
    }
};
</script>

<style lang="less" src="./DragToScroll.less"></style>
