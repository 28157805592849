import BugsnagPluginVue from "@bugsnag/plugin-vue";
export default function enhanceOptions(moduleOptions) {
  const options = { ...moduleOptions };
  options.plugins = [...options.plugins || [], new BugsnagPluginVue()];
  options.onError = (event) => {
    event.errors[0].stacktrace = event.errors[0].stacktrace.map((row) => {
      row.file = row.file.replace("file://", "");
      return row;
    });
  };
  return options;
}
