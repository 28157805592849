import { NOT_VALIDATED, INVALID, VALID, VALIDATION_DISABLED } from '../constants/validation-states';

export default {
    props: {
        wrapperAttrs: {
            type: Object,
            required: false,
            default: undefined
        },

        /**
         * UI related
         */
        label: {
            type: String,
            default: ''
        },

        clearable: {
            type: Boolean,
            default: true
        },

        forceFloat: {
            type: Boolean,
            default: false
        },

        showStatus: {
            type: Boolean,
            default: false
        },

        /**
         * Model related
         */
        modelValue: {
            type: [String, Number],
            default: undefined
        },

        /**
         * Validation related
         */
        validator: {
            type: Object,
            default: undefined
        }
    },

    data() {
        return {
            isFocussed: false
        };
    },

    computed: {
        hasValue() {
            return this.modelValue !== null && this.modelValue !== undefined && this.modelValue !== '';
        },

        hasLabel() {
            return this.label !== null && this.label !== undefined && this.label !== '';
        },

        hasStatus() {
            return this.showStatus || !!this.$slots.status;
        },

        showClearButton() {
            return this.clearable && this.hasValue;
        },

        defaultInputAttrs() {
            return {
                autofocus: false,
                novalidate: true,
            };
        },

        defaultWrapperAttrs() {
            const attrs = {
                'data-focus': this.isFocussed,
                'data-disabled': !!this.$attrs?.disabled,
                'data-readonly': !!this.$attrs?.readonly,
                'data-floating': this.isFloating
            };

            if (this.validator) {
                attrs['data-validation'] = this.validationState;
            }

            return attrs;
        },

        slotProps() {
            return {
                characterCount: this.characterCount,
                value: this.modelValue,
                validation: this.validationState
            };
        },

        isFloating() {
            return this.forceFloat || (this.hasLabel && (this.isFocussed || this.hasValue));
        },

        characterCount() {
            return this.modelValue ? this.modelValue.length : 0;
        },

        validationState() {
            if (!this.validator) {
                return VALIDATION_DISABLED;
            }

            if (this.validator.validated && this.validator.valid) {
                return VALID;
            }

            if (this.validator.validated && !this.validator.valid) {
                return INVALID;
            }

            return NOT_VALIDATED;
        }
    },

    methods: {
        onFocusInput() {
            this.$emit('focus');
            this.isFocussed = true;
        },

        onBlurInput() {
            this.$emit('blur');
            this.isFocussed = false;
        },

        onInput($event) {
            this.$emit('update:modelValue', $event.target.value);
        },

        onChange($event) {
            this.$emit('change', $event);
        },

        onClear() {
            this.$emit('clear');
            this.$emit('update:modelValue', '');
        },

        onClickWrapper() {
            this.$refs.input.focus();
        }
    }
};
