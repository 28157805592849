import { toValue } from "vue";
import { murmurHash } from "ohash";
import { flatMapDeep } from "lodash-es";
export default (thing) => {
  return murmurHash(
    JSON.stringify(
      flatMapDeep(
        thing,
        (value, index) => [index, toValue(value)]
      )
    )
  );
};
