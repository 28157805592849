import { createClient } from "villus";
import { defineNuxtPlugin } from "#imports";
import { dnGraphQlClient } from "./keys.mjs";
import useConfig from "#build/dn-gql.options.mjs";
import errorDebugger from "./plugins/errorDebugger.mjs";
const DnGqlPlugin = async (vue) => {
  const config = useConfig();
  config.villus.use?.unshift(errorDebugger());
  const client = createClient(config?.villus ?? {});
  vue.provide(dnGraphQlClient, client);
};
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(DnGqlPlugin);
});
