<template>
    <div
        class="dn-input"
        :class="{
            'dn-input--has-floating-label': !!label
        }"
        v-bind="extendedWrapperAttrs"
        @click="onClickWrapper"
    >
        <div
            v-if="hasPre"
            class="dn-input__pre"
        >
            <slot
                name="pre"
                v-bind="slotProps"
            />
        </div>

        <div class="dn-input__field">
            <div class="dn-input__field-body">
                <input
                    ref="input"
                    :value="modelValue"
                    v-bind="extendedInputAttrs"
                    @focus="onFocusInput"
                    @blur="onBlurInput"
                    @input="onInput"
                    @change="onChange"
                >

                <label
                    v-if="label"
                    class="dn-input__label"
                >
                    {{ label }}
                </label>
            </div>

            <transition name="dn-input-fade">
                <div
                    v-if="showClearButton || hasStatus"
                    class="dn-input__field-aside"
                >
                    <transition name="dn-input-fade">
                        <button
                            v-if="showClearButton"
                            key="clear"
                            type="button"
                            class="dn-input__clear"
                            title="Reset"
                            tabindex="-1"
                            @click="onClear"
                        >
                            <slot name="clear">⨉</slot>
                        </button>
                    </transition>

                    <transition name="dn-input-fade">
                        <div
                            v-if="hasStatus"
                            v-bind="slotProps"
                            class="dn-input__status"
                        >
                            <slot name="status" />
                        </div>
                    </transition>
                </div>
            </transition>
        </div>

        <div
            v-if="hasAfter"
            class="dn-input__after"
        >
            <slot
                name="after"
                v-bind="slotProps"
            />
        </div>
    </div>
</template>

<script>
'use strict';

import InputMixin from './mixins/input';

export default {
    name: 'DnInput',

    mixins: [InputMixin],

    inheritAttrs: false,

    computed: {
        hasPre() {
            return !!this.$slots.pre;
        },

        hasAfter() {
            return !!this.$slots.after;
        },

        extendedWrapperAttrs() {
            return {
                ...this.defaultWrapperAttrs,
                'data-has-pre': this.hasPre,
                'data-has-after': this.hasAfter,
                ...this.wrapperAttrs,
            };
        },

        extendedInputAttrs() {
            return {
                ...this.defaultInputAttrs,
                ...this.$attrs,
            };
        }
    }
};

</script>

<style lang="less" src="./input.less"></style>
