import Bugsnag from "@bugsnag/js";
import enhanceOptions from "../utils/enhanceOptions.mjs";
import { defineNuxtPlugin, useRuntimeConfig } from "#imports";
import mockBugsnag from "../utils/mockBugsnag.mjs";
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const options = enhanceOptions(config.public.bugsnag);
  let client = Bugsnag._client;
  if (client === null) {
    try {
      client = Bugsnag.start(options);
    } catch (error) {
      console.log("[Bugsnag] started in mock mode");
      return {
        provide: {
          bugsnag: mockBugsnag
        }
      };
    }
  }
  nuxtApp.vueApp.provide("bugsnag-client", client);
  const plugin = Bugsnag.getPlugin("vue");
  if (plugin !== void 0) {
    nuxtApp.vueApp.use(plugin);
  }
  return {
    provide: {
      bugsnag: client
    }
  };
});
