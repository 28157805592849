import { definePlugin } from "villus";
import { print } from "graphql/language/printer";
import { consola } from "consola";
export default () => {
  const logger = consola.withTag("graphql");
  return definePlugin(({ operation, opContext, afterQuery }) => {
    afterQuery(({ error }) => {
      if (error) {
        const operationContext = {
          endpoint: opContext.url,
          headers: opContext.headers,
          query: typeof operation.query === "string" ? operation.query : print(operation.query),
          type: operation.type,
          variables: operation.variables
        };
        if (import.meta?.env?.MODE === "development") {
          logger.error(error, operationContext);
        }
      }
    });
  });
};
