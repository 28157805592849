import { computed, inject, readonly, reactive, toValue } from "vue";
import { useQuery } from "villus";
import { useAsyncData, useLazyAsyncData, useRoute } from "#app";
import hash from "../lib/hash.mjs";
import { dnGraphQlClient } from "../keys.mjs";
export async function useAsyncQuery(villusOpts, nuxtOpts = {}) {
  const route = useRoute();
  const key = computed(() => {
    const token = route?.query?.["x-craft-live-preview"];
    return hash({ ...villusOpts, token }).toString(36);
  });
  const client = inject(dnGraphQlClient);
  if (!client) {
    throw new Error("Villus client could not be found, make sure to register the plugin correctly.");
  }
  const rOpts = readonly(reactive(villusOpts));
  return useAsyncData(
    toValue(key),
    asyncHandler(client, villusOpts, nuxtOpts?.onComplete),
    { watch: [rOpts], ...nuxtOpts }
    // fixme: find out how to cast correctly instead of to any to silence TS!
  );
}
export async function useLazyAsyncQuery(villusOpts, nuxtOpts = {}) {
  const route = useRoute();
  const key = computed(() => {
    const token = route?.query?.["x-craft-live-preview"];
    return hash({ ...villusOpts, token }).toString(36);
  });
  const client = inject(dnGraphQlClient);
  if (!client) {
    throw new Error("Villus client could not be found, make sure to register the plugin correctly.");
  }
  const rOpts = readonly(reactive(villusOpts));
  return useLazyAsyncData(
    toValue(key),
    asyncHandler(client, villusOpts, nuxtOpts?.onComplete),
    { watch: [rOpts], ...nuxtOpts }
    // fixme: find out how to cast correctly instead of to any to silence TS!
  );
}
function asyncHandler(client, villusOpts, onComplete) {
  return async () => {
    const response = await useQuery({
      ...villusOpts,
      ...{ client },
      paused: true
      // disable re-fetching feature of villus, Nuxt AsyncData will do this and call this callback if anything changes.
    });
    if (response?.error?.value) {
      const combinedError = response.error.value;
      const errorText = combinedError?.message ?? "Something went wrong";
      const errorMessage = combinedError?.response?.status ? `${errorText} [${combinedError.response.status}]` : errorText;
      throw new Error(errorMessage);
    }
    if (onComplete) {
      await onComplete(response);
    }
    return toValue(response?.data);
  };
}
