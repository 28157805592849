<template>
    <component
        :is="element"
        class="dn-button"
        v-bind="$attrs"
    >
        <slot />
    </component>
</template>

<script>
'use strict';

export default {
    name: 'DnButton',

    props: {
        element: {
            type: [String, Object],
            required: false,
            default: 'button'
        }
    }
};
</script>

<style lang="less" src="./button.less"></style>
