import Bugsnag from '@bugsnag/js';
import { definePlugin } from 'villus';

/**
 * Reports unknown errors to Bugsnag to avoid having to do that everywhere.
 */
const villusBugsnag = (options) => {
    // If options.shouldReport is empty, or not a function, fallback to reporting everything
    let shouldReport = function(error) {
        return !!error;
    };
    if (typeof options?.shouldReport === 'function') {
        shouldReport = options.shouldReport;
    }

    return definePlugin(({ operation, afterQuery }) => {
        afterQuery(({ error }, { response }) => {
            // Notify bugsnag of any not explicilty ignored non-fatal GQL errors
            if (shouldReport(error) || (response?.status || 200) >= 400) {
                // Collect some information about the query that failed
                const operationContext = {
                    query: operation.query,
                    type: operation.type,
                    variables: operation.variables,
                };
                Bugsnag.notify(error, function(event) {
                    event.addMetadata('response', response);
                    event.addMetadata('operation', operationContext);
                });
            }
        });
    });
};

export default villusBugsnag;
