/**
  * villus v3.5.1
  * (c) 2024 Abdelrahman Awad
  * @license MIT
  */
import { isRef, toValue, isReactive, nextTick, getCurrentInstance, inject, provide, onBeforeUnmount, ref, watch, onMounted, computed } from 'vue';
import { GraphQLError, print } from 'graphql';

function getDefaultExportFromCjs (x) {
	return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, 'default') ? x['default'] : x;
}

var fastJsonStableStringify = function (data, opts) {
    if (!opts) opts = {};
    if (typeof opts === 'function') opts = { cmp: opts };
    var cycles = (typeof opts.cycles === 'boolean') ? opts.cycles : false;

    var cmp = opts.cmp && (function (f) {
        return function (node) {
            return function (a, b) {
                var aobj = { key: a, value: node[a] };
                var bobj = { key: b, value: node[b] };
                return f(aobj, bobj);
            };
        };
    })(opts.cmp);

    var seen = [];
    return (function stringify (node) {
        if (node && node.toJSON && typeof node.toJSON === 'function') {
            node = node.toJSON();
        }

        if (node === undefined) return;
        if (typeof node == 'number') return isFinite(node) ? '' + node : 'null';
        if (typeof node !== 'object') return JSON.stringify(node);

        var i, out;
        if (Array.isArray(node)) {
            out = '[';
            for (i = 0; i < node.length; i++) {
                if (i) out += ',';
                out += stringify(node[i]) || 'null';
            }
            return out + ']';
        }

        if (node === null) return 'null';

        if (seen.indexOf(node) !== -1) {
            if (cycles) return JSON.stringify('__cycle__');
            throw new TypeError('Converting circular structure to JSON');
        }

        var seenIndex = seen.push(node) - 1;
        var keys = Object.keys(node).sort(cmp && cmp(node));
        out = '';
        for (i = 0; i < keys.length; i++) {
            var key = keys[i];
            var value = stringify(node[key]);

            if (!value) continue;
            if (out) out += ',';
            out += JSON.stringify(key) + ':' + value;
        }
        seen.splice(seenIndex, 1);
        return '{' + out + '}';
    })(data);
};

var stringify = /*@__PURE__*/getDefaultExportFromCjs(fastJsonStableStringify);

function unravel(signal, vars) {
    if (isRef(signal)) {
        return signal.value;
    }
    if (typeof signal === 'function') {
        return signal(toValue(vars));
    }
    return signal;
}
function isWatchable(val) {
    return isRef(val) || isReactive(val) || typeof val === 'function';
}
function arrayToExistHash(items) {
    return items.reduce((acc, item) => {
        acc[String(item)] = true;
        return acc;
    }, {});
}
function debounceAsync(inner) {
    let resolves = [];
    let ticking = false;
    return function (...args) {
        if (!ticking) {
            nextTick(() => {
                const result = inner(...args);
                resolves.forEach(r => r(result));
                resolves = [];
                ticking = false;
            });
            ticking = true;
        }
        return new Promise(resolve => resolves.push(resolve));
    };
}
function isEqual(lhs, rhs) {
    return stringify(lhs) === stringify(rhs);
}
function useCallback() {
    const hooks = new Set();
    function on(handler) {
        hooks.add(handler);
        return () => {
            hooks.delete(handler);
        };
    }
    async function run(...args) {
        for (const hook of hooks) {
            await hook(...args);
        }
    }
    return {
        on,
        run,
    };
}

// https://github.com/FormidableLabs/urql/blob/master/src/utils/error.ts
const generateErrorMessage = (networkError, graphqlErrors) => {
    let error = '';
    if (networkError !== undefined) {
        return (error = `[Network] ${networkError.message}`);
    }
    if (graphqlErrors !== undefined) {
        graphqlErrors.forEach(err => {
            error += `[GraphQL] ${err.message}\n`;
        });
    }
    return error.trim();
};
function normalizeGqlError(error) {
    if (typeof error === 'string') {
        return new GraphQLError(error);
    }
    if (typeof error === 'object' && error.message) {
        return new GraphQLError(error.message, error.nodes, error.source, error.positions, error.path, error, error.extensions || {});
    }
    return error;
}
class CombinedError extends Error {
    constructor({ response, networkError, graphqlErrors, }) {
        const gqlErrors = graphqlErrors === null || graphqlErrors === void 0 ? void 0 : graphqlErrors.map(normalizeGqlError);
        const message = generateErrorMessage(networkError, gqlErrors);
        super(message);
        this.name = 'CombinedError';
        this.response = response;
        this.message = message;
        this.networkError = networkError;
        this.graphqlErrors = gqlErrors;
    }
    get isGraphQLError() {
        return !!(this.graphqlErrors && this.graphqlErrors.length);
    }
    toString() {
        return this.message;
    }
}

/**
 * Normalizes a query string or object to a string.
 */
function normalizeQuery(query) {
    if (typeof query === 'string') {
        return query;
    }
    // Supports typed document strings in 3.2
    if (query && query instanceof String) {
        return query.toString();
    }
    if (query && 'kind' in query) {
        return print(query);
    }
    return null;
}

async function parseResponse(response) {
    let json;
    const responseData = {
        ok: response.ok,
        statusText: response.statusText,
        status: response.status,
        headers: response.headers,
    };
    try {
        json = await response.json();
    }
    catch (err) {
        return Object.assign(Object.assign({}, responseData), { statusText: err.message, body: null });
    }
    return Object.assign(Object.assign({}, responseData), { body: json });
}
function resolveGlobalFetch() {
    if (typeof window !== 'undefined' && 'fetch' in window && window.fetch) {
        return window.fetch.bind(window);
    }
    if (typeof global !== 'undefined' && 'fetch' in global) {
        return global.fetch;
    }
    if (typeof self !== 'undefined' && 'fetch' in self) {
        return self.fetch;
    }
    return undefined;
}
const DEFAULT_FETCH_OPTS = {
    method: 'POST',
    headers: {
        'content-type': 'application/json',
    },
};
function mergeFetchOpts(lhs, rhs) {
    return Object.assign(Object.assign(Object.assign({}, lhs), rhs), { method: rhs.method || lhs.method || DEFAULT_FETCH_OPTS.method, headers: Object.assign(Object.assign({}, (lhs.headers || {})), (rhs.headers || {})) });
}
function makeFetchOptions({ query, variables }, opts) {
    const normalizedQuery = normalizeQuery(query);
    if (!normalizedQuery) {
        throw new Error('A query must be provided.');
    }
    return mergeFetchOpts({ body: JSON.stringify({ query: normalizedQuery, variables }) }, opts);
}

function hash(x) {
    let h, i, l;
    for (h = 5381 | 0, i = 0, l = x.length | 0; i < l; i++) {
        h = (h << 5) + h + x.charCodeAt(i);
    }
    return h >>> 0;
}
function getQueryKey(operation, ...components) {
    const variables = operation.variables ? stringify(operation.variables) : '';
    const query = normalizeQuery(operation.query);
    return hash(`${query}${variables}${components.join('')}`);
}

function cache() {
    let resultCache = {};
    function setCacheResult({ key, tags }, result) {
        resultCache[key] = { result, tags };
    }
    function getCachedResult({ key }) {
        var _a;
        return (_a = resultCache[key]) === null || _a === void 0 ? void 0 : _a.result;
    }
    function clearCache(tags) {
        if (!tags) {
            resultCache = {};
            return;
        }
        const tagArray = Array.isArray(tags) ? tags : [tags];
        if (!tagArray.length) {
            return;
        }
        const tagsLookup = arrayToExistHash(tagArray);
        // clears cache keys one by one
        Object.keys(resultCache).forEach(key => {
            const cacheItem = resultCache[key];
            if (!cacheItem.tags) {
                return;
            }
            const tagExists = cacheItem.tags.some(t => tagsLookup[t]);
            if (tagExists) {
                delete resultCache[key];
            }
        });
    }
    function cachePlugin({ afterQuery, useResult, operation }) {
        var _a;
        if (operation.type === 'mutation' && ((_a = operation.clearCacheTags) === null || _a === void 0 ? void 0 : _a.length)) {
            afterQuery(result => {
                // only after successful operation
                if (result.data) {
                    clearCache(operation.clearCacheTags);
                }
            });
            return;
        }
        if (operation.type !== 'query' || operation.cachePolicy === 'network-only') {
            return;
        }
        // Set the cache result after query is resolved
        afterQuery(result => {
            setCacheResult(operation, result);
        });
        // Get cached item
        const cachedResult = getCachedResult(operation);
        if (operation.cachePolicy === 'cache-only') {
            return useResult(cachedResult || { data: null, error: null }, true);
        }
        // if exists in cache, terminate with result
        if (cachedResult) {
            return useResult(cachedResult, operation.cachePolicy === 'cache-first');
        }
    }
    cachePlugin.clearCache = clearCache;
    return cachePlugin;
}

function fetch(opts) {
    const fetch = (opts === null || opts === void 0 ? void 0 : opts.fetch) || resolveGlobalFetch();
    if (!fetch) {
        throw new Error('Could not resolve a fetch() method, you should provide one.');
    }
    return async function fetchPlugin(ctx) {
        var _a, _b;
        const { useResult, opContext, operation } = ctx;
        const fetchOpts = makeFetchOptions(operation, opContext);
        let response;
        try {
            response = await fetch(opContext.url, fetchOpts).then(parseResponse);
        }
        catch (err) {
            return useResult({
                data: null,
                error: new CombinedError({ response, networkError: err }),
            }, true);
        }
        // Set the response on the context
        ctx.response = response;
        const data = (_a = response.body) === null || _a === void 0 ? void 0 : _a.data;
        if (!response.ok || !response.body) {
            // It is possible than a non-200 response is returned with errors, it should be treated as GraphQL error
            const ctorOptions = {
                response,
            };
            if ((_b = response.body) === null || _b === void 0 ? void 0 : _b.errors) {
                ctorOptions.graphqlErrors = response.body.errors;
            }
            else {
                ctorOptions.networkError = new Error(response.statusText);
            }
            return useResult({
                data,
                error: new CombinedError(ctorOptions),
            }, true);
        }
        useResult({
            data,
            error: response.body.errors ? new CombinedError({ response, graphqlErrors: response.body.errors }) : null,
        }, true);
    };
}

function dedup() {
    // Holds references to pending operations
    const pendingLookup = {};
    return function dedupPlugin(ctx) {
        // Don't dedup mutations or subscriptions
        if (ctx.operation.type !== 'query') {
            return;
        }
        // extract the original useResult function
        const { useResult } = ctx;
        // Clean up pending queries after they are resolved
        ctx.afterQuery(() => {
            delete pendingLookup[ctx.operation.key];
        });
        // If pending, re-route the result to it
        const existingOp = pendingLookup[ctx.operation.key];
        if (existingOp) {
            return existingOp.then(result => {
                useResult(result, true);
            });
        }
        // Hold a resolve fn reference
        let resolveOp;
        // Create a pending operation promise and add it to lookup
        pendingLookup[ctx.operation.key] = new Promise(resolve => {
            resolveOp = resolve;
        });
        // resolve the promise once the result are set via another plugin
        ctx.useResult = function (...args) {
            useResult(...args);
            resolveOp(args[0]);
        };
    };
}

const VILLUS_CLIENT = Symbol('villus.client');

/**
 * setActiveClient should be called to solve problem outside setup
 */
let activeClient;
/**
 * Sets or unsets the active client
 *
 * @param client - villus client instance
 */
const setActiveClient = (client) => (activeClient = client);
/**
 * Get the currently active client if there is any.
 */
const getActiveClient = () => {
    var _a;
    const vm = getCurrentInstance();
    if (!vm) {
        return activeClient;
    }
    return ((_a = vm.provides) === null || _a === void 0 ? void 0 : _a[VILLUS_CLIENT]) || inject(VILLUS_CLIENT, activeClient);
};
const defaultPlugins = () => [cache(), dedup(), fetch()];
class Client {
    constructor(opts) {
        this.install = () => undefined;
        this.url = opts.url;
        this.defaultCachePolicy = opts.cachePolicy || 'cache-first';
        this.plugins = opts.use || [...defaultPlugins()];
        this.taggedQueries = [];
    }
    /**
     * Executes an operation and returns a normalized response.
     */
    async execute(operation, type, queryContext, onResultChanged) {
        let result;
        const opContext = Object.assign(Object.assign({ url: this.url }, DEFAULT_FETCH_OPTS), { headers: Object.assign(Object.assign({}, DEFAULT_FETCH_OPTS.headers), ((queryContext === null || queryContext === void 0 ? void 0 : queryContext.headers) || {})) });
        let terminateSignal = false;
        const afterQuery = [];
        const context = {
            useResult(pluginResult, terminate) {
                if (terminate) {
                    terminateSignal = true;
                }
                // this means the `useResult` was called multiple times
                if (result) {
                    onResultChanged === null || onResultChanged === void 0 ? void 0 : onResultChanged(pluginResult);
                }
                result = pluginResult;
            },
            afterQuery(cb) {
                afterQuery.push(cb);
            },
            operation: Object.assign(Object.assign({}, operation), { key: getQueryKey(operation), type, cachePolicy: ('cachePolicy' in operation ? operation.cachePolicy : this.defaultCachePolicy) || this.defaultCachePolicy }),
            opContext,
        };
        let lastI = 0;
        for (let i = 0; i < this.plugins.length; i++) {
            const plugin = this.plugins[i];
            await plugin(context);
            if (result) {
                lastI = i;
                break;
            }
        }
        return new Promise((resolve, reject) => {
            if (!result) {
                reject(new Error('Operation result was not set by any plugin, make sure you have default plugins configured or review documentation'));
                return;
            }
            resolve(result);
            (async () => {
                if (!terminateSignal) {
                    for (let i = lastI + 1; i < this.plugins.length; i++) {
                        const plugin = this.plugins[i];
                        await plugin(context);
                    }
                }
                const afterQueryCtx = { response: context.response };
                for (let i = 0; i < afterQuery.length; i++) {
                    const afterCb = afterQuery[i];
                    await afterCb(result, afterQueryCtx);
                }
            })();
        });
    }
    async executeQuery(operation, queryContext, onResultChanged) {
        return this.execute(operation, 'query', queryContext, onResultChanged);
    }
    async executeMutation(operation, queryContext) {
        return this.execute(operation, 'mutation', queryContext);
    }
    async executeSubscription(operation) {
        const result = await this.execute(operation, 'subscription');
        return result;
    }
    registerTaggedQuery(tags, refetch) {
        const id = Symbol('Tagged query');
        this.taggedQueries.push({ id, tags, refetch });
        return id;
    }
    unregisterTaggedQuery(id) {
        const idx = this.taggedQueries.findIndex(tq => tq.id === id);
        if (idx === -1) {
            return;
        }
        this.taggedQueries.splice(idx, 1);
    }
    async refetchTaggedQueries(tags) {
        const tagsLookup = arrayToExistHash(tags);
        const queries = this.taggedQueries.filter(tq => {
            return tq.tags.some(t => tagsLookup[t]);
        });
        return Promise.all(queries.map(q => q.refetch())).then(() => undefined);
    }
}
function createClient(opts) {
    const client = new Client(opts);
    client.install = (app) => {
        // this allows useQuery() etc useFunctions to get client outside of a component setup after
        setActiveClient(client);
        app.provide(VILLUS_CLIENT, client);
    };
    return client;
}
function resolveInternalInjection(vm, symbol) {
    var _a, _b, _c, _d;
    // Vue 2 uses `proxy._provided` while Vue 3 has `provides`
    // Vue 2.7's proxy property might be a bug but thats the IRL situation.
    return ((_b = (_a = vm === null || vm === void 0 ? void 0 : vm.proxy) === null || _a === void 0 ? void 0 : _a._provided) === null || _b === void 0 ? void 0 : _b[symbol]) || ((_c = vm === null || vm === void 0 ? void 0 : vm._provided) === null || _c === void 0 ? void 0 : _c[symbol]) || ((_d = vm === null || vm === void 0 ? void 0 : vm.provides) === null || _d === void 0 ? void 0 : _d[symbol]);
}
function resolveClient() {
    const vm = getCurrentInstance();
    // Uses same component provide as its own injections
    // Due to changes in https://github.com/vuejs/vue-next/pull/2424
    let client = vm && inject(VILLUS_CLIENT, resolveInternalInjection(vm, VILLUS_CLIENT));
    if (client)
        setActiveClient(client);
    client = getActiveClient();
    if (client === null || client === undefined) {
        throw new Error('Cannot detect villus Client, did you forget to call `useClient`? Alternatively, you can explicitly pass a client as the `manualClient` argument.');
    }
    return client;
}

function useClient(opts) {
    const client = createClient(opts);
    if (getCurrentInstance()) {
        provide(VILLUS_CLIENT, client);
    }
    return client;
}

const defaultMapper = (val) => val.data;
function useQuery(opts, mapData = defaultMapper) {
    var _a;
    const client = (_a = opts === null || opts === void 0 ? void 0 : opts.client) !== null && _a !== void 0 ? _a : resolveClient();
    if (opts.tags) {
        const id = client.registerTaggedQuery(opts.tags, async () => {
            await execute();
        });
        onBeforeUnmount(() => {
            client.unregisterTaggedQuery(id);
        });
    }
    const { query, variables, cachePolicy, fetchOnMount, paused, skip, onData: dataHook, onError: errorHook, } = normalizeOptions(opts);
    let currentFetchOnMount = fetchOnMount;
    const data = ref(defaultMapper({ data: null, error: null }));
    let lastResult = { data: null, error: null };
    const isFetching = ref(fetchOnMount !== null && fetchOnMount !== void 0 ? fetchOnMount : false);
    const isDone = ref(false);
    const isStale = ref(true);
    const error = ref(null);
    const { on: onData, run: executeDataHooks } = useCallback();
    const { on: onError, run: executeErrorHooks } = useCallback();
    if (dataHook) {
        onData(dataHook);
    }
    if (errorHook) {
        onError(errorHook);
    }
    // This is to prevent state mutation for racing requests, basically favoring the very last one
    let lastPendingOperation;
    const isCurrentlyPaused = () => unravel(paused, (variables || {}));
    function onResultChanged(result) {
        if (result.data) {
            executeDataHooks(result.data);
        }
        if (result.error) {
            executeErrorHooks(result.error);
        }
        data.value = mapData(result);
        error.value = result.error;
    }
    async function execute(overrideOpts) {
        const vars = toValue(variables) || {};
        // result won't change if execution is skipped
        if (unravel(skip, vars)) {
            isFetching.value = false;
            return {
                data: (lastResult === null || lastResult === void 0 ? void 0 : lastResult.data) || null,
                error: (lastResult === null || lastResult === void 0 ? void 0 : lastResult.error) || null,
            };
        }
        isFetching.value = true;
        const pendingExecution = client.executeQuery({
            query: toValue(query),
            variables: toValue((overrideOpts === null || overrideOpts === void 0 ? void 0 : overrideOpts.variables) || vars),
            cachePolicy: (overrideOpts === null || overrideOpts === void 0 ? void 0 : overrideOpts.cachePolicy) || cachePolicy,
            tags: opts === null || opts === void 0 ? void 0 : opts.tags,
        }, toValue(opts === null || opts === void 0 ? void 0 : opts.context), onResultChanged);
        lastPendingOperation = pendingExecution;
        const res = await pendingExecution;
        // Avoid state mutation if the pendingExecution isn't the last pending operation
        if (pendingExecution !== lastPendingOperation) {
            // we still return this result to preserve the integrity of "execute" calls
            return { data: res.data, error: res.error };
        }
        lastResult = res;
        onResultChanged(res);
        isDone.value = true;
        isFetching.value = false;
        isStale.value = false;
        lastPendingOperation = undefined;
        return { data: res.data, error: res.error };
    }
    function executeIfNotPaused() {
        const isPaused = isCurrentlyPaused();
        if (!isPaused) {
            execute();
        }
    }
    if (isWatchable(query)) {
        watch(query, executeIfNotPaused);
    }
    if (isWatchable(paused)) {
        watch(() => !isCurrentlyPaused(), shouldExecute => {
            if (shouldExecute && isStale.value) {
                execute();
            }
        });
    }
    function initVarWatchers() {
        let oldCache;
        if (!variables || !isWatchable(variables)) {
            return;
        }
        watch(() => toValue(variables), newValue => {
            const id = hash(stringify(newValue));
            // prevents duplicate queries.
            if (id === oldCache) {
                return;
            }
            oldCache = id;
            isStale.value = true;
            executeIfNotPaused();
        }, { deep: true });
    }
    initVarWatchers();
    const api = { data, isFetching, isDone, error, execute, onData, onError };
    /**
     * if can not getCurrentInstance, the func use outside of setup, cannot get onMounted
     * when outside of setup and fetchOnMount is true, execute immediately, but a little confused
     * todo: maybe better to add a new param decide execute immediately, but it's ok also now
     */
    const vm = getCurrentInstance();
    if (currentFetchOnMount) {
        if (!paused || !isCurrentlyPaused()) {
            vm ? onMounted(() => execute()) : execute();
        }
    }
    return Object.assign(Object.assign({}, api), { async then(onFulfilled) {
            currentFetchOnMount = false;
            await api.execute();
            return onFulfilled(api);
        } });
}
function normalizeOptions(opts) {
    const defaultOpts = {
        variables: {},
        fetchOnMount: true,
    };
    return Object.assign(Object.assign(Object.assign({}, defaultOpts), opts), { query: opts.query });
}

function useMutation(query, opts) {
    var _a;
    const client = (_a = opts === null || opts === void 0 ? void 0 : opts.client) !== null && _a !== void 0 ? _a : resolveClient();
    const data = ref(null);
    const isFetching = ref(false);
    const isDone = ref(false);
    const error = ref(null);
    // This is to prevent state mutation for racing requests, basically favoring the very last one
    let lastPendingOperation;
    async function execute(variables) {
        var _a, _b;
        isFetching.value = true;
        const vars = variables || {};
        const pendingExecution = client.executeMutation({
            query,
            variables: vars, // FIXME: fix this casting
            clearCacheTags: [...((opts === null || opts === void 0 ? void 0 : opts.clearCacheTags) || []), ...((opts === null || opts === void 0 ? void 0 : opts.refetchTags) || [])],
        }, toValue(opts === null || opts === void 0 ? void 0 : opts.context));
        lastPendingOperation = pendingExecution;
        const res = await pendingExecution;
        nextTick(() => {
            if (opts === null || opts === void 0 ? void 0 : opts.refetchTags) {
                client.refetchTaggedQueries(opts.refetchTags);
            }
        });
        // Avoid state mutation if the pendingExecution isn't the last pending operation
        if (pendingExecution !== lastPendingOperation) {
            // we still return this result to preserve the integrity of "execute" calls
            return { data: res.data, error: res.error };
        }
        if (res.data)
            (_a = opts === null || opts === void 0 ? void 0 : opts.onData) === null || _a === void 0 ? void 0 : _a.call(opts, res.data);
        if (res.error)
            (_b = opts === null || opts === void 0 ? void 0 : opts.onError) === null || _b === void 0 ? void 0 : _b.call(opts, res.error);
        data.value = res.data;
        error.value = res.error;
        isDone.value = true;
        isFetching.value = false;
        lastPendingOperation = undefined;
        return { data: data.value, error: error.value };
    }
    return { data, isFetching, isDone, error, execute };
}

const defaultReducer = val => val.data;
function useSubscription(opts, reduce = defaultReducer) {
    var _a, _b, _c, _d;
    const client = (_a = opts.client) !== null && _a !== void 0 ? _a : resolveClient();
    const { query, variables, paused, skip } = opts;
    const subscribeOnMount = (_b = opts.subscribeOnMount) !== null && _b !== void 0 ? _b : true;
    const unsubscribeOnUnmount = (_c = opts.unsubscribeOnUnmount) !== null && _c !== void 0 ? _c : true;
    const data = ref((_d = opts === null || opts === void 0 ? void 0 : opts.initialData) !== null && _d !== void 0 ? _d : reduce({ data: null, error: null }, null));
    const error = ref(null);
    const isPaused = computed(() => unravel(paused, variables));
    const isFetching = ref(true);
    function handleResponse(result) {
        data.value = reduce(result, data.value);
        error.value = result.error;
    }
    /**
     * if can not getCurrentInstance, the func use outside of setup, cannot get onMounted
     * when outside of setup initObserver immediately.
     */
    let observer;
    const subscribe = debounceAsync(async function subscribe() {
        unsubscribe();
        if (shouldSkip()) {
            return;
        }
        isFetching.value = true;
        const result = await client.executeSubscription({
            query: toValue(query),
            variables: toValue(variables),
        });
        observer = result.subscribe({
            next(result) {
                if (isPaused.value) {
                    return;
                }
                const response = transformResult(result);
                isFetching.value = false;
                handleResponse(response);
            },
            complete() { },
            error(err) {
                if (isPaused.value) {
                    return;
                }
                const response = { data: null, error: new CombinedError({ networkError: err, response: null }) };
                isFetching.value = false;
                return handleResponse(response);
            },
        });
        return observer;
    });
    function unsubscribe() {
        observer === null || observer === void 0 ? void 0 : observer.unsubscribe();
        observer = undefined;
    }
    const vm = getCurrentInstance();
    if (!isPaused.value && !shouldSkip() && subscribeOnMount) {
        vm ? onMounted(subscribe) : subscribe();
    }
    if (vm && unsubscribeOnUnmount) {
        // TODO: if outside of setup, it should be recommend manually pause it(or some action else)
        onBeforeUnmount(unsubscribe);
    }
    function shouldSkip() {
        return unravel(skip, toValue(variables) || {});
    }
    if (isWatchable(paused)) {
        watch(paused, val => {
            if (!val) {
                subscribe();
            }
        });
    }
    if (isWatchable(query)) {
        watch(query, subscribe);
    }
    if (isWatchable(variables)) {
        watch(variables, (value, oldValue) => {
            if (!isEqual(value, oldValue)) {
                subscribe();
            }
        });
    }
    if (isWatchable(skip)) {
        watch(shouldSkip, (value, oldValue) => {
            if (value === oldValue) {
                return;
            }
            value ? unsubscribe() : subscribe();
        });
    }
    function isSubscribed() {
        return !!observer;
    }
    return {
        data,
        error,
        paused: isPaused,
        isFetching,
        subscribe: () => {
            subscribe();
        },
        unsubscribe,
        isSubscribed,
    };
}
/**
 * Transforms the result from a standard operation result to villus result
 */
function transformResult(result) {
    if (!result.errors) {
        return { data: result.data || null, error: null };
    }
    return {
        data: result.data || null,
        error: new CombinedError({ graphqlErrors: [...result.errors], response: null }),
    };
}

function handleSubscriptions(forwarder) {
    const forward = forwarder;
    return async function subscriptionsHandlerPlugin({ operation, useResult }) {
        if (operation.type !== 'subscription') {
            return;
        }
        if (!forward) {
            throw new Error('No subscription forwarder was set.');
        }
        const normalizedQuery = normalizeQuery(operation.query);
        if (!normalizedQuery) {
            throw new Error('A query must be provided.');
        }
        const result = await forward(Object.assign(Object.assign({}, operation), { query: normalizedQuery }));
        useResult(result, true);
    };
}

function definePlugin(fn) {
    return fn;
}

export { Client, CombinedError, VILLUS_CLIENT, cache, createClient, dedup, defaultPlugins, definePlugin, fetch, getActiveClient, getQueryKey, handleSubscriptions, makeFetchOptions, mergeFetchOpts, normalizeQuery, parseResponse, setActiveClient, useClient, useMutation, useQuery, useSubscription };
