<template>
    <Teleport
        :to="teleportTarget"
        :disabled="teleportDisabled"
    >
        <Transition
            :name="transitionName"
            mode="out-in"
        >
            <FocusLoop
                v-if="isElementInDOM"
                v-show="isVisible"
                :is-visible="isElementInDOM"
                :disabled="!isVisible"
                class="dn-modal"
                v-bind="$attrs"
                role="dialog"
            >
                <div
                    class="dn-modal__wrapper"
                    tabindex="-1"
                    @keydown.esc="$emit('close')"
                >
                    <div class="dn-modal__dialog">
                        <slot
                            v-if="!hideDefaultCloseButton"
                            name="close-button"
                        >
                            <button
                                class="dn-modal__close"
                                aria-label="close"
                                @click="$emit('close')"
                            >
                                <slot name="close-button-label">⨉</slot>
                            </button>
                        </slot>

                        <div
                            v-if="!!$slots.default"
                            class="dn-modal__main"
                        >
                            <slot />
                        </div>

                        <slot name="dialog" />
                    </div>

                    <slot name="wrapper" />

                    <div
                        class="dn-modal__backdrop"
                        @click="$emit('close')"
                    />
                </div>

                <slot name="root" />
            </FocusLoop>
        </Transition>
    </Teleport>
</template>

<script>
import { FocusLoop } from '@vue-a11y/focus-loop';

export default {
    name: 'DnModal',

    components: {
        FocusLoop
    },

    inheritAttrs: false,

    props: {
        isVisible: {
            type: Boolean,
            default: false
        },

        preventBodyScroll: {
            type: Boolean,
            default: true
        },

        hideDefaultCloseButton: {
            type: Boolean,
            default: false
        },

        transitionName: {
            type: String,
            default: 'dn-modal'
        },

        teleportTarget: {
            type: String,
            default: 'body'
        },

        teleportDisabled: {
            type: Boolean,
            default: false
        },
        removeFromDomWhenInvisible: {
            type: Boolean,
            default: true
        }
    },

    emits: ['close'],

    computed: {
        isElementInDOM() {
            if (this.removeFromDomWhenInvisible && !this.isVisible) {
                return false;
            }

            return true;
        }
    },

    watch: {
        isVisible(value) {
            this.setBodyAttributes();
        }
    },

    unmounted() {
        document.body.removeAttribute('data-modal-open');
        document.body.removeAttribute('data-prevent-scrolling');
    },

    methods: {
        setBodyAttributes() {
            if (!document || !document.body) {
                return;
            }

            if (this.isVisible) {
                document.body.setAttribute('data-modal-open', 'true');
            } else {
                document.body.removeAttribute('data-modal-open');
            }

            if (this.preventBodyScroll && this.isVisible) {
                document.body.setAttribute('data-prevent-scrolling', 'true');
            } else {
                document.body.removeAttribute('data-prevent-scrolling');
            }
        }
    }
};
</script>

<style lang="less" src="./modal.less"></style>
